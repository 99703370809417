import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useAppForm, useAppLoading } from ".";
import { Spinner } from "./spinner";

import Tick from "@svg/tick.svg";

import One from "@images/one.png";

export const EntryForm = ({ error, isSubmitted, setOpen }) => {
  const {
    handleChange,
    handleSub,
    handleSubmit,
    handleReset,
    handleErrorReset,
  } = useAppForm();
  const [checked, setChecked] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [termsError, setTermsError] = useState(false);

  const isLoading = useAppLoading();

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleTerms = () => {
    setTermsChecked(!termsChecked);
  };

  // set Sub state to true on first load
  useEffect(() => {
    return handleSub(true);
  }, []);

  const handleTermsError = () => {
    if (termsChecked) {
      setTermsError(false);
    } else {
      setTermsError(true);
    }
  };

  return (
    <>
      <div className="relative pb-40">
        <div className="relative pt-20 z-20">
          <div className="max-w-[530px]  mx-auto relative">
            <div className="relative mx-auto max-w-[280px] sm:max-w-[600px] sm:w-[600px]">
              <div className="absolute -top-5 -left-10">
                <img src={One} alt="" className="w-[44px] object-contain" />
              </div>
              <h2 className="blockH1 uppercase  text-center sm:text-left  pl-4">
                enter your details to wing big
              </h2>
            </div>

            {isSubmitted ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="blockH2 mt-10 px-3 text-center"
              >
                <p>thank you.</p>
                <br />
                <p>
                  you should receive your voucher code in your inbox. enjoy.
                </p>
              </motion.div>
            ) : (
              <form className="" onSubmit={handleSubmit} autocomplete="off">
                <div>
                  {/* Form */}
                  <div className="flex flex-col   gap-y-5  mt-6 px-4  ">
                    <div class="relative z-0  w-full group">
                      <input
                        name="firstName"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="firstName"
                        className="regularLabel pointer-events-none "
                      >
                        Your first name
                      </label>
                    </div>

                    <div class="relative z-0  w-full group ">
                      <input
                        name="lastName"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer "
                      />
                      <label
                        for="lastName"
                        className="regularLabel pointer-events-none "
                      >
                        Your last name
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="email"
                        type="email"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="email"
                        className="regularLabel pointer-events-none "
                      >
                        Your email address
                      </label>
                    </div>

                    <div className="flex items-start relative  blockB4 mb-4 sm:mb-0  w-fit ">
                      <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        checked={termsChecked}
                        onChange={handleTerms}
                      />
                      <label
                        for="terms"
                        className=" mx-auto font-bold letter-spacing-8 relative "
                      >
                        <div
                          className=" w-[24px] h-[24px]  absolute -top-1 -left-4 transition-all duration-300 ease-in-out bg-white"
                          // onClick={() =>
                          //   handleTerms(termsChecked ? false : true)
                          // }
                          // onClick={() => console.log("clicked")}
                        >
                          {termsChecked && (
                            <Tick className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
                          )}
                        </div>

                        <span className="block ml-6 ">
                          <span> I have read and agree to the</span>
                        </span>
                      </label>
                      <span
                        className="text-pink font-bold blockB4 absolute -bottom-4 sm:bottom-[1px]  left-10 sm:left-[215px] xl:left-[218px] cursor-pointer underline underline-offset-4 w-full capitalize"
                        onClick={() => setOpen(true)}
                      >
                        {" "}
                        Terms & Conditions*
                      </span>
                    </div>
                    <div className="flex items-start relative  blockB4 mb-5 w-fit ">
                      <input
                        type="checkbox"
                        name="subscribe"
                        id="subscribe"
                        checked={checked}
                        onChange={handleChecked}
                      />
                      <label
                        for="subscribe"
                        className=" mx-auto font-bold letter-spacing-8 relative "
                      >
                        <div
                          className=" w-[24px] h-[24px]  absolute -top-1 -left-4 transition-all duration-300 ease-in-out bg-white"
                          onClick={() => handleSub(checked ? false : true)}
                        >
                          {checked && (
                            <Tick className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
                          )}
                        </div>

                        <span className="block ml-6 ">
                          I would like to receive further communication from TAB
                        </span>
                      </label>
                    </div>

                    <button
                      type={termsChecked ? "submit" : "button"}
                      onClick={handleTermsError}
                      className="flex items-center gap-x-2 button-two"
                    >
                      <span>get your free wings</span>
                      {isLoading && (
                        <div className="ml-4">
                          <Spinner />
                        </div>
                      )}
                    </button>
                    {error === "emailError" && (
                      <p className="text-center text-pink">
                        Email address already used.
                      </p>
                    )}
                    {error === "otherError" && (
                      <p className="text-center text-pink">
                        An error occured. Please try again.
                      </p>
                    )}
                    {termsError && (
                      <p className="text-center text-pink">
                        Please accept the terms and conditions.
                      </p>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
