import React from "react";

import AdBannerOne from "@images/ad-banner-one.jpg";
import AdBannerTwo from "@images/ad-banner-two.jpg";

const AdBanners = () => {
  return (
    <div className="">
      <div className="space-y-5  flex flex-col justify-center items-center my-20 px-4">
        <a
          className="block shadow-md"
          href="https://www.tab.co.nz/create-account"
          target="_blank"
        >
          <img
            src={AdBannerOne}
            alt=""
            className="w-full max-w-[692px] object-contain"
          />
        </a>
        <a
          className="block shadow-md"
          href="https://www.tab.co.nz/punters/offr/offers/super-bowl-bonus"
          target="_blank"
        >
          <img
            src={AdBannerTwo}
            alt=""
            className="w-full max-w-[692px] object-contain"
          />
        </a>
      </div>
      <div className="text-center">
        <h3 className="blockH1 mb-5">got a wing problem?</h3>
        <a
          className="uppercase button-one  flex items-center"
          href="mailto:helpdesk@wingbig.co.nz"
          target="_blank"
        >
          contact us here
        </a>
      </div>
    </div>
  );
};

export default AdBanners;
