import React from "react";
import { AnimatePresence } from "framer-motion";

import Cross from "@svg/cross.svg";

const TermsPopup = ({ handleClose }) => {
  return (
    <AnimatePresence>
      <div className="background-blue mx-5 lg:mx-auto rounded-[20px] overflow-hidden my-auto">
        <div className="  w-full max-w-[1100px] h-full max-h-[90vh] lg:max-h-[70vh] self-center   relative  overflow-y-scroll font-corona ">
          <div
            onClick={handleClose}
            className="sticky top-0 right-16 cursor-pointer z-50  rounded-full background-blue w-full h-20 "
          >
            <Cross className="w-[29px] md:w-[40px] absolute top-1/2 right-10 -translate-y-1/2 " />
          </div>
          <div className="px-4 py-28 sm:px-10 lg:px-20 ">
            <div className="uppercase  text-center">
              <h2 className="blockH1    ">TAB Super bowl wing big giveaway</h2>
              <h3 className="blockH2">Terms and conditions</h3>
            </div>

            <div className="my-10 px-4">
              <ol className="list-decimal space-y-6 blockB3	">
                <li>
                  All Entrants{" "}
                  <span style={{ fontWeight: "bold" }}>(Entrants)</span> who
                  participate in TAB Super Bowl Wing Big Giveaway Competition{" "}
                  <span style={{ fontWeight: "bold" }}>(the Competition)</span>{" "}
                  are deemed to accept these terms and conditions of entry
                  (Terms and Conditions). Any entries for the Competition that
                  do not comply with the Terms and Conditions will be deemed
                  invalid and TAB New Zealand (TAB) takes no responsibility for
                  such entries. All times given in the Terms and Conditions are
                  references to New Zealand Local Time and all references to
                  currency are referring to New Zealand Dollars.
                </li>
                <li>
                  TAB is the promoter of the Competition. TAB’s Head Office is
                  106-110 Jackson Street, Petone, 5012 and its phone number is:
                  +64 4 576 6999.
                </li>
                <li>
                  TAB is partnering with Delivereasy Limited{" "}
                  <span style={{ fontWeight: "bold" }}>(Delivereasy)</span> for
                  the Competition. Delivereasy’s Head Office is Level 4, 180
                  Taranaki Street, Te Aro, Wellington, 6011.
                </li>
                <li>
                  The Competition is not sponsored, endorsed or administered by,
                  or in association with the NFL, commercial partners of the NFL
                  or affiliated companies and organisations of the NFL.
                </li>
                <h3 className="font-bold blockB2 pt-8">
                  Registration for the Competition
                </h3>
                <li>
                  The Competition entry period begins at 8:00am on Friday 10
                  February 2023 and ends at 5:00pm on Monday 13 February 2023 or
                  when the Delivereasy vouchers are exhausted (the{" "}
                  <span style={{ fontWeight: "bold" }}>Competition Period</span>
                  ).
                </li>
                <h3 className="font-bold blockB2 pt-8">Eligible Entrants</h3>
                <li>
                  To be eligible for entry into the Competition and for ongoing
                  participation all Entrants to the Competition must be eighteen
                  (18) years of age or over from the time the Entrant first
                  participates in the Competition and during the Competition
                  Period.
                </li>
                <li>
                  TAB reserves the right to require any Entrant to produce
                  identification to verify their age or identity.
                </li>
                <h3 className="font-bold blockB2 pt-8">Competition Entry</h3>
                <li>
                  An Entrant will earn a competition entry{" "}
                  <span style={{ fontWeight: "bold" }}>
                    (Competition Entry)
                  </span>
                  by inserting their email address on the Competitions website
                  (www.wingbig.co.nz) (the{" "}
                  <span style={{ fontWeight: "bold" }}>Website</span>) where
                  indicated.
                </li>
                <li>
                  An Entrant will only receive a maximum of one (1) Competition
                  Entry per email address.
                </li>
                <h3 className="font-bold blockB2 pt-8">Competition Prize</h3>
                <li>
                  Each Prize Winner will receive one (1) fifteen (15) dollar
                  Delivereasy voucher (the{" "}
                  <span style={{ fontWeight: "bold" }}>Prize</span>).
                </li>
                <li>
                  There is a limit of five thousand (5,000) Prizes to be won.
                  The first five thousand (5,000) emails registered on the
                  Website will receive the Prize{" "}
                  <span style={{ fontWeight: "bold" }}>(Prize Winners)</span>.
                  TAB and Delivereasy may distribute additional Prizes at their
                  discretion.
                </li>
                <li>
                  Each Prize Winner shall receive the Prize by email to the
                  email address they register on the Website.
                </li>

                <li>
                  Usage of the Prize is the sole discretion of the Prize Winner.
                </li>
                <li>
                  The Prize is only redeemable from Delivereasy, for delivery
                  only and in locations where Delivereasy operates.
                </li>
                <li>
                  The Prize is only redeemable from when the Prize Winner
                  receives the Prize until midnight (12:00am) on Sunday 19th
                  February 2023, at which time the Prize will expire.
                </li>
                <li>
                  TAB takes no responsibility if a Prize Winner is unable to use
                  the Prize for any reason whatsoever, including where part of
                  the Prize is performed by a third party (including
                  Delivereasy).
                </li>
                <h3 className="font-bold blockB2 pt-8">General</h3>
                <li>
                  Prize Winners agree to be involved in any reasonable
                  promotional activities stipulated by TAB (without
                  compensation), including but not limited to the publication of
                  the Prize Winner’s name, place of residence, and any images.
                  TAB will own the copyright in any such images and
                  photograph(s) and in all material incorporating the
                  photograph(s) or film.
                </li>
                <li>
                  TAB reserves the right, at any time, to verify the validity of
                  any Competition Entry, and to disqualify as ineligible to
                  participate in the Competition, in any way, any Entrant who
                  acts in a manner that is not in accordance with the Terms and
                  Conditions, and/or who tampers with the eligibility process
                  and/or attempts to act in a fraudulent and/or dishonest
                  manner.
                </li>
                <li>
                  The following persons are not eligible to enter the
                  Competition or win the Prize:
                </li>
                <ol
                  type="a"
                  style={{ listStyleType: "lower-alpha" }}
                  className="pl-6"
                >
                  <li>
                    Any excluded customer of TAB (being a customer who has
                    self-excluded from TAB’s products, as well as any customer
                    who has been excluded by TAB); and
                  </li>
                  <li>
                    Any customer involved in TAB accounts established for a
                    syndicate, commercial or group use; and
                  </li>
                  <li>
                    TAB Board members, employees, agents (and agent’s employees)
                    and contractors of TAB and “Immediate Families” of such
                    persons.
                  </li>
                </ol>

                <li>
                  For the purposes of condition 19, “
                  <span style={{ fontWeight: "bold" }}>Immediate Family</span>”
                  of a person are the parents, siblings, children and spouse or
                  partner (whether legal or de facto) of that person (and
                  Immediate Families shall have a corresponding meaning).
                </li>
                <li>
                  If this Competition cannot be run as planned for any reason,
                  including for reasons beyond TAB’s control, TAB reserves the
                  right in its sole discretion to cancel, terminate, modify or
                  suspend the Competition (or any part of the Competition), at
                  any time.
                </li>
                <li>
                  TAB takes no responsibility for any inability to enter,
                  complete, continue or conclude the Competition (or any part of
                  the Competition) due to equipment or technical malfunction,
                  Acts of God or otherwise.
                </li>
                <li>
                  Except for any liability that cannot be excluded by law, TAB
                  and any person in connection with the Competition are not
                  liable for any direct, indirect, special or consequential loss
                  or damage whatsoever suffered or personal injury suffered or
                  sustained in connection with:
                </li>
                <ol
                  type="a"
                  style={{ listStyleType: "lower-alpha" }}
                  className="pl-6"
                >
                  <li>Registration for or participation in the Competition;</li>
                  <li>The use (or non-use) of the Prize; and </li>
                  <li>The administration of the Competition. </li>
                </ol>
                <li>
                  Failure by TAB to enforce any of its rights under the Terms
                  and Conditions at any stage does not constitute a waiver of
                  those rights.
                </li>
                <li>
                  Any personal information provided by an Entrant participating
                  in the Competition will be held in accordance with the Privacy
                  Act 2020. The information collected and held by TAB about you
                  may be used by TAB from time to time to:
                </li>
                <ol
                  type="a"
                  style={{ listStyleType: "lower-alpha" }}
                  className="pl-6"
                >
                  <li>
                    Administer the Competition and verify compliance with the
                    Terms and Conditions;
                  </li>
                  <li>Ensure fairness and the integrity of the Competition;</li>
                  <li>Provide you with notifications, reminders and offers;</li>
                  <li>
                    Assess your eligibility to receive specific products and
                    services;
                  </li>
                  <li>
                    Offer you products or services that TAB considers may be of
                    interest to you;
                  </li>
                  <li>
                    Assess your eligibility for participation in any promotional
                    events which TAB may be running;
                  </li>
                  <li>
                    Carry out general market research and analysis relating to
                    TAB’s products and services generally;
                  </li>
                  <li>
                    To invite you to participate in surveys, sweepstakes,
                    competitions and similar promotions.
                  </li>
                </ol>
                <li>
                  TAB may match or combine the personal information you provide
                  when entering the Competition with other personal information
                  about you TAB has obtained from other sources or that may
                  already be on TAB’s records, whether that information was
                  collected online or in physical form, and may use information
                  already held in the administration of the Competition.
                </li>
                <li>
                  Pursuant to the Privacy Act 2020, you have the right to view
                  the personal information you have provided when entering the
                  Competition. You may also request to review and correct any of
                  that personal information by contacting TAB. Additional rights
                  may apply under applicable law.
                </li>
                <li>
                  By entering the Competition, an Entrant agrees to receive
                  marketing and promotional material from TAB and its authorised
                  agents (including electronic material).
                </li>
                <li>
                  Entrants into the Competition agree TAB may disclose the
                  Entrant’s personal and Competition Entry information to third
                  parties in connection with any of the purposes stated under
                  condition 25, provided the recipient of the information is
                  subject to an obligation of confidentiality in relation to the
                  disclosed information.
                </li>
                <li>
                  Notwithstanding condition 29, TAB may disclose your personal
                  information to particular organisations both within New
                  Zealand and overseas for the purposes of investigating and/or
                  detecting breaches of any relevant legislation of New Zealand,
                  or of another jurisdiction.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TermsPopup;
