import React from "react";

import Re from "@images/r18.png";
import LogoTab from "@images/logo-tab.png";
import LogoDeliveryeasy from "@images/logo-delivereasy-wide.png";
import ReStacked from "@images/logo-r18-stacked.png";

const Footer = ({ setOpen }) => {
  return (
    <div className=" background-footer py-10  px-4 xl:px-40 ">
      <a
        href="https://www.tab.co.nz/create-account"
        target="_blank"
        className="sm:hidden w-full inline-block mb-10"
      >
        <img
          src={LogoTab}
          alt=""
          className="w-[118px] object-contain mx-auto"
        />
      </a>
      <div className="flex justify-between sm:justify-center items-center relative cursor-pointer">
        <div
          onClick={() => setOpen(true)}
          className=" sm:absolute  sm:top-1/2 sm:-translate-y-1/2 sm:left-0"
        >
          <img
            src={Re}
            alt=""
            className="w-[217px] lg:w-[290px] object-contain hidden md:block "
          />
          <img
            src={ReStacked}
            alt=""
            className="w-[144px] object-contain md:hidden"
          />
        </div>
        <a
          href="https://www.tab.co.nz/create-account"
          target="_blank"
          className="hidden sm:block  "
        >
          <img
            src={LogoTab}
            alt=""
            className="w-[137px] lg:w-[195px] object-contain"
          />
        </a>
        <a
          href="https://www.delivereasy.co.nz/l/tabwings"
          target="_blank"
          className=" sm:absolute sm:top-1/2 sm:-translate-y-1/2 right-0"
        >
          <img
            src={LogoDeliveryeasy}
            alt=""
            className="w-[130px] lg:w-[163px] xl:w-[215px] object-contain"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
