import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAppForm } from ".";
import Countdown from "./countdown";

import BannerTab from "@images/banner-tab.png";
import BannerTabMobile from "@images/banner-tab-mobile.png";
import BannerTabTablet from "@images/banner-tab-tablet.png";
import HeroChicken from "@images/hero-chicken.png";
import HeroChickenEmpty from "@images/hero-chicken-empty.png";
import VoucherCounter from "@images/voucher-counter.png";

import Zero from "@images/0.png";
import One from "@images/1.png";
import Two from "@images/2.png";
import Three from "@images/3.png";
import Four from "@images/4.png";
import Five from "@images/5.png";
import Six from "@images/6.png";
import Seven from "@images/7.png";
import Eight from "@images/8.png";
import Nine from "@images/9.png";

const Banner = ({ isAge, totalCount, isSubmitted }) => {
  const { fetchAvailableTickets } = useAppForm();
  const [fourDigits, setFourDigits] = useState("9999");

  // store number pngs in array
  const numbers = [Zero, One, Two, Three, Four, Five, Six, Seven, Eight, Nine];

  useEffect(async () => {
    await fetchAvailableTickets();
  }, []);

  useEffect(() => {
    setFourDigits(totalCount.toString().padStart(4, "0"));
  }, [totalCount]);

  console.log("fourDigits", fourDigits);

  // sunstract one off setFourDigits after submit
  useEffect(() => {
    if (isSubmitted) {
      setFourDigits((prev) => {
        const newCount = parseInt(prev) - 1;
        return newCount.toString().padStart(4, "0");
      });
    }
  }, [isSubmitted]);

  return (
    <>
      {/* desktop */}
      <motion.div
        initial={{ height: "100vh" }}
        animate={{ height: isAge ? "80vh" : "100vh" }}
        transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
        className=" overflow-hidden relative banner-background min-h-[700px] 2xl:min-h-[1000px] hidden lg:block 2xl:hidden"
      >
        <div className="absolute top-0 left-0 w-full px-24 z-[7000]">
          <img
            src={BannerTab}
            alt=""
            className="w-full object-contain max-w-[1270px] mx-auto "
          />
        </div>

        <motion.h2
          initial={{ opacity: 1, y: 0, scale: 1 }}
          animate={{
            opacity: isAge ? 0 : 1,
            y: isAge ? -50 : 0,
            scale: isAge ? 0.5 : 1,
          }}
          transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
          className="text-[222px] w-full absolute top-[5vw] font-prohibition text-center  "
        >
          WING BIG
        </motion.h2>
        <div className="banner-lightning ">
          <motion.div
            initial={{
              bottom: -450,
            }}
            animate={{
              bottom: isAge ? -10 : -450,
            }}
            transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
            className=" absolute  left-1/2 -translate-x-1/2  z-40 w-full flex justify-center items-center flex-shrink-0 "
          >
            <motion.div
              initial={{ width: "2000px", minWidth: "2000px" }}
              animate={{
                width: isAge ? "1320px" : "2000px",
                minWidth: isAge ? "1320px" : "2000px",
              }}
              transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
              className="h-full "
            >
              <motion.img
                src={totalCount === 0 ? HeroChickenEmpty : HeroChicken}
                alt=""
                className="w-full h-full    "
              />
            </motion.div>
          </motion.div>
        </div>

        <div className="background-blue absolute bottom-0 left-0 w-full h-[112px] z-40" />

        <Countdown fourDigits={fourDigits} numbers={numbers} />
      </motion.div>

      {/* mobile */}
      <motion.div
        initial={{ height: "90vh" }}
        animate={{ height: isAge ? "70vh" : "90vh" }}
        transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
        className="sm:hidden  overflow-hidden relative banner-background "
      >
        <div className="absolute top-0 left-0 w-full z-[7000]">
          <img
            src={BannerTabMobile}
            alt=""
            className="w-full object-contain  mx-auto sm:hidden "
          />
        </div>
        <motion.h2
          initial={{ opacity: 1, y: 0, scale: 1 }}
          animate={{
            opacity: isAge ? 0 : 1,
            y: isAge ? -50 : 0,
            scale: isAge ? 0.5 : 1,
          }}
          transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
          className="text-[110px] w-full absolute top-20 sm:top-28  font-prohibition text-center leading-none  "
        >
          WING
          <br />
          BIG
        </motion.h2>
        <div className="banner-lightning-mobile ">
          <motion.div
            initial={{
              bottom: -300,
            }}
            animate={{
              bottom: isAge ? 50 : -300,
            }}
            transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
            className=" absolute  left-1/2 -translate-x-1/2  z-40 w-full flex  justify-center items-center flex-shrink-0 "
          >
            <motion.div
              initial={{ width: "1350px", minWidth: "1350px" }}
              animate={{
                width: isAge ? "800px" : "1350px",
                minWidth: isAge ? "800px" : "1350px",
              }}
              transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
              className="h-full "
            >
              <motion.img
                src={totalCount === 0 ? HeroChickenEmpty : HeroChicken}
                alt=""
                className="w-full h-full    "
              />
            </motion.div>
          </motion.div>
        </div>

        <div className="background-blue absolute bottom-0 left-0 w-full h-[112px] z-40" />
        <Countdown fourDigits={fourDigits} numbers={numbers} />
      </motion.div>

      {/* tablet */}
      <motion.div
        initial={{ height: "100vh" }}
        animate={{ height: isAge ? "80vh" : "100vh" }}
        transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
        className=" overflow-hidden relative banner-background min-h-[700px] 2xl:min-h-[1000px] hidden sm:block lg:hidden"
      >
        <div className="absolute top-0 left-0 w-full px-24 z-[7000]">
          <img
            src={BannerTabTablet}
            alt=""
            className="w-full object-contain  mx-auto hidden sm:block "
          />
        </div>
        <motion.h2
          initial={{ opacity: 1, y: 0, scale: 1 }}
          animate={{
            opacity: isAge ? 0 : 1,
            y: isAge ? -50 : 0,
            scale: isAge ? 0.5 : 1,
          }}
          transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
          className="text-[222px] w-full absolute top-[5vw] font-prohibition text-center  "
        >
          WING BIG
        </motion.h2>
        <div className="banner-lightning-tablet ">
          <motion.div
            initial={{
              bottom: -450,
            }}
            animate={{
              bottom: isAge ? -10 : -450,
            }}
            transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
            className=" absolute  left-1/2 -translate-x-1/2  z-40 w-full flex justify-center items-center flex-shrink-0 "
          >
            <motion.div
              initial={{ width: "2300px", minWidth: "2300px" }}
              animate={{
                width: isAge ? "1700px" : "2300px",
                minWidth: isAge ? "1700px" : "2300px",
              }}
              transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
              className="h-full "
            >
              <motion.img
                src={totalCount === 0 ? HeroChickenEmpty : HeroChicken}
                alt=""
                className="w-full h-full    "
              />
            </motion.div>
          </motion.div>
        </div>
        <div className="background-blue absolute bottom-0 left-0 w-full h-[112px] z-40" />
        <Countdown fourDigits={fourDigits} numbers={numbers} />
      </motion.div>

      {/* large screen */}
      <motion.div
        initial={{ height: "100vh" }}
        animate={{ height: isAge ? "80vh" : "100vh" }}
        transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
        className=" overflow-hidden relative banner-background min-h-[700px] 2xl:min-h-[1000px] hidden 2xl:block"
      >
        <div className="absolute top-0 left-0 w-full px-24 z-[7000] ">
          <img
            src={BannerTab}
            alt=""
            className="w-full object-contain max-w-[1270px] mx-auto "
          />
        </div>
        <motion.h2
          initial={{ opacity: 1, y: 0, scale: 1 }}
          animate={{
            opacity: isAge ? 0 : 1,
            y: isAge ? -50 : 0,
            scale: isAge ? 0.5 : 1,
          }}
          transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
          className="text-[222px] w-full absolute top-[5vw] font-prohibition text-center  "
        >
          WING BIG
        </motion.h2>
        <div className="banner-lightning ">
          <motion.div
            initial={{
              bottom: -450,
            }}
            animate={{
              bottom: isAge ? -10 : -450,
            }}
            transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
            className=" absolute  left-1/2 -translate-x-1/2  z-40 w-full flex justify-center items-center flex-shrink-0 "
          >
            <motion.div
              initial={{ width: "3000px", minWidth: "3000px" }}
              animate={{
                width: isAge ? "2000px" : "3000px",
                minWidth: isAge ? "2000px" : "3000px",
              }}
              transition={{ duration: 1.5, delay: 1, ease: "easeInOut" }}
              className="h-full "
            >
              <motion.img
                src={totalCount === 0 ? HeroChickenEmpty : HeroChicken}
                alt=""
                className="w-full h-full    "
              />
            </motion.div>
          </motion.div>
        </div>
        <div className="background-blue absolute bottom-0 left-0 w-full h-[112px] z-40" />
        <Countdown fourDigits={fourDigits} numbers={numbers} />
      </motion.div>
    </>
  );
};

export default Banner;
