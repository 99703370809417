import React from "react";

import Eagles from "@images/eagles.png";
import Chiefs from "@images/chiefs.png";

const Odds = () => {
  return (
    <div>
      <h2 className="blockH1 text-center">
        lick the sauce off your fingers and place a bet
      </h2>
      <div className="flex items-center justify-center mt-5 space-x-[25px]">
        <div className="lg:flex flex-row-reverse ">
          <div className=" lg:ml-8">
            <img
              src={Chiefs}
              alt=""
              className="w-[140px] sm:w-[182px] object-contain"
            />
          </div>
          <div className="text-center">
            <h3 className="font-prohibition text-[40px] sm:text-[72px] xl:text-[86px] leading-[1.2em]">
              CHIEFS
            </h3>
            <p className="blockH1">$1.97</p>
          </div>
        </div>
        <p className="blockH1">VS</p>
        <div className="lg:flex ">
          <div className="lg:mr-8">
            <img
              src={Eagles}
              alt=""
              className="w-[140px] sm:w-[182px] object-contain"
            />
          </div>
          <div className="text-center">
            <h3 className="font-prohibition text-[40px] sm:text-[72px] xl:text-[86px] leading-[1.2em]">
              EAGLES
            </h3>
            <p className="blockH1">$1.83</p>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <a
          className="uppercase button-one  flex items-center"
          href="https://www.tab.co.nz/superbowl"
          target="_blank"
        >
          tab.co.nz/superbowl
        </a>
        <p className="blockB3 mt-5 text-center">
          Odds displayed are for NZ$1 and are subject to change.
        </p>
      </div>
    </div>
  );
};

export default Odds;
