import React from "react";
import { motion } from "framer-motion";

import Box from "@svg/box.svg";

const Countdown = ({ numbers, fourDigits }) => {
  return (
    <div className="absolute bottom-[74px] sm:bottom-[58px] left-0 z-[6000] w-full flex justify-center">
      <div className="flex space-x-1">
        <div className="relative">
          <Box className="absolute top-1/2 -translate-y-1/2 -left-1" />
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            src={numbers[fourDigits[0]]}
            alt=""
            key={fourDigits[0]}
            className="w-full object-contain max-w-[54px] sm:max-w-[76px]   "
          />
          <Box className="absolute top-1/2 -translate-y-1/2 -right-[6px] z-[8000]" />
        </div>

        <div className="relative">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            src={numbers[fourDigits[1]]}
            key={fourDigits[1]}
            alt=""
            className="w-full object-contain max-w-[54px] sm:max-w-[76px] ] "
          />
          <Box className="absolute top-1/2 -translate-y-1/2 -right-[6px] z-[8000]" />
        </div>
        <div className="relative">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            src={numbers[fourDigits[2]]}
            key={fourDigits[2]}
            alt=""
            className="w-full object-contain max-w-[54px] sm:max-w-[76px]  "
          />
          <Box className="absolute top-1/2 -translate-y-1/2 -right-[6px] z-[8000]" />
        </div>
        <div className="relative">
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            src={numbers[fourDigits[3]]}
            key={fourDigits[3]}
            alt=""
            className="w-full object-contain max-w-[54px] sm:max-w-[76px]  "
          />
          <Box className="absolute top-1/2 -translate-y-1/2 -right-[6px] z-[8000]" />
        </div>
      </div>
      <div className="w-full h-[1px] bg-white absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
      <h2 className="blockH1 text-center absolute -bottom-14 left-1/2 -translate-x-1/2">
        VOUCHERS LEFT
      </h2>
    </div>
  );
};

export default Countdown;
