import React, { createContext, useContext, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { EntryForm } from "./entryForm";
import AgeGate from "./ageGate";
import TermsPopup from "./termsPopup";
import Banner from "./banner";
import OtherSteps from "./otherSteps";
import Odds from "./odds";
import AdBanners from "./adBanners";
import Footer from "./footer";

import PortalModal from "@components/UI/portalModal";

const initialState = {
  isAge: false,
  isSubmitted: false,
  formValues: {},
  error: false,
  totalCount: 9999,
};

const isClient = typeof window === "object";

export const AppContext = createContext({
  compState: initialState,
  setAppState: () => null,
});

const FormInner = () => {
  const { isSubmitted, isAge, error, totalCount } = useAppState();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (isSubmitted && typeof window !== "undefined") {
      dataLayer = window.dataLayer || [];
      dataLayer.push({ event: "submitted-successful" });
    }
  }, [isSubmitted]);

  function getNoMoreTicketsText() {
    const currentTime = Date.now();
    const isPastSundayMidnight =
      currentTime > Date.parse("12 February 2023 00:00 GMT+13:00");
    const isPastMondayMidnight =
      currentTime > Date.parse("13 February 2023 00:00 GMT+13:00");

    if (!isPastMondayMidnight && isPastSundayMidnight) {
      return (
        <>
          <h2 className="blockH1 mb-10">WOW, You guys love your wings!</h2>
          <p className="blockH2">
            We’ve run out for today, but check back on game day.
          </p>
        </>
      );
    }

    if (isPastMondayMidnight) {
      return (
        <>
          <h2 className="blockH1 mb-10">SORRY, WE’VE RUN OUT OF WINGS.</h2>
          <p className="blockH2 max-w-xl mx-auto mb-10">
            Missing out on free wings is tough, but there’s bigger things to
            worry about, like who’s going to win the Super Bowl… Eagles or
            Chiefs?
          </p>
          <p className="blockH2 max-w-xl mx-auto">
            And for those with vouchers, remember you can redeem these up to
            close of service Sunday (Feb 19th)
          </p>
        </>
      );
    }

    return (
      <>
        <h2 className="blockH1 mb-10">WOW, You guys love your wings!</h2>
        <p className="blockH2">
          We’ve run out for today, but check back tomorrow.
        </p>
      </>
    );
  }

  return (
    <>
      <AnimatePresence mode="wait">
        <div className="h-full overflow-hidden w-full">
          <Banner
            isAge={isAge}
            totalCount={totalCount}
            isSubmitted={isSubmitted}
          />
          {totalCount !== 0 ? (
            <div className="background-blue">
              <EntryForm
                isSubmitted={isSubmitted}
                error={error}
                setOpen={setOpen}
              />
              <OtherSteps />
            </div>
          ) : (
            <div className="text-center background-blue py-[100px] uppercase px-4">
              {getNoMoreTicketsText()}
            </div>
          )}

          <div className="background-pink py-[100px]">
            <Odds />
            <AdBanners />
          </div>
          <Footer setOpen={setOpen} />
          {/* ageGate modal */}
          <PortalModal isShowing={!isAge}>
            <AgeGate />
          </PortalModal>

          {/* terms modal starts */}
          <PortalModal isShowing={open}>
            <TermsPopup handleClose={handleClose} />
          </PortalModal>
        </div>
      </AnimatePresence>
    </>
  );
};

const VoucherForm = () => {
  const [compState, setCompState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{ compState, setCompState, isLoading, setLoading }}
    >
      <div>
        <FormInner />
      </div>
    </AppContext.Provider>
  );
};

function useAppState() {
  const { compState } = useContext(AppContext);
  return compState;
}

function useAppLoading() {
  const { isLoading } = useContext(AppContext);
  return isLoading;
}

function useAgeGate() {
  const { setCompState } = useContext(AppContext);

  function setAge() {
    setCompState((prevState) => ({
      ...prevState,
      isAge: true,
    }));
  }

  return { setAge };
}

function useAppForm() {
  const { setCompState, compState, setLoading } = useContext(AppContext);

  // Form Change Function
  function handleChange(event) {
    event.persist();
    setCompState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value.toUpperCase(),
      },
    }));
  }

  function handleSub(check) {
    setCompState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        subscribe: check,
      },
    }));
  }

  async function fetchCompResult(data) {
    const response = await fetch("/.netlify/functions/handle-entry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      return response;
    }
  }

  // retrieve available tickets via Sanity
  async function fetchAvailableTickets() {
    const response = await fetch("/.netlify/functions/handle-sanity", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });

    if (response.status === 200) {
      // store entries in state
      const data = await response.json();
      setCompState((prevState) => ({
        ...prevState,
        totalCount: data,
      }));
    }
  }

  // Form Handle Submit
  async function handleSubmit(event) {
    event.preventDefault();

    // Set Loading
    setLoading(true);
    // Await response from endpoint
    const res = await fetchCompResult({
      ...compState.formValues,
    });
    const data = await res.json();
    console.log("Response", data);

    setLoading(false);

    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: data.submitted,
      error: !data.emailValid ? "emailError" : "otherError",
    }));
  }

  // Provide a reset
  function handleReset() {
    isClient && window.scrollTo(0, 0);
    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: false,
    }));
  }

  function handleErrorReset() {
    isClient && window.scrollTo(0, 0);
    setCompState((prevState) => ({
      ...prevState,
      error: false,
    }));
  }

  return {
    handleChange,
    handleSub,
    handleReset,
    handleErrorReset,
    handleSubmit,
    fetchAvailableTickets,
  };
}

export { VoucherForm, useAppState, useAppForm, useAppLoading, useAgeGate };
