import React from "react";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { useTermsQuery } from "@querys/useTermsQuery";

import { VoucherForm } from "../components/voucherForm";
// import Placeholder from "../components/voucherForm/placeholder";

const IndexPage = () => {
  const { seo } = useTermsQuery();
  return (
    <Layout>
      <PageMeta {...seo} />
      <VoucherForm />
      {/* <Placeholder /> */}
    </Layout>
  );
};

export default IndexPage;

// export function Head() {
//   return (
//     <script
//       dangerouslySetInnerHTML={{
//         __html:
//           typeof window !== "undefined" &&
//           (function (w, d, s, l, i) {
//             w[l] = w[l] || [];
//             w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
//             var f = d.getElementsByTagName(s)[0],
//               j = d.createElement(s),
//               dl = l != "dataLayer" ? "&l=" + l : "";
//             j.async = true;
//             j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
//             f.parentNode.insertBefore(j, f);
//           })(window, document, "script", "dataLayer", "GTM-WDDHCNQ"),
//       }}
//     />
//   );
// }
