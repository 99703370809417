import React, { useState, useRef } from "react";
import { AnimatePresence } from "framer-motion";
import { useAgeGate } from ".";

import AgeTop from "@images/age-top.png";
import AgeTopMobile from "@images/age-top-mobile.png";

const AgeGate = () => {
  const { setAge } = useAgeGate();
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(0);
  const [dateMessage, setDateMessage] = useState("");

  const dayFocus = useRef();
  const monthFocus = useRef();
  const yearFocus = useRef();
  const enterFocus = useRef();

  const underAgeValidate = (birthday) => {
    let birthDate = new Date(birthday);
    let ageDifMs = Date.now() - birthDate.getTime();
    if (ageDifMs < 0) {
      return false;
    }
    let ageDate = new Date(ageDifMs);
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age > 17) {
      return true;
    } else {
      return false;
    }
  };

  const handleEnter = () => {
    const formattedDate = `${year}/${month}/${day}`;
    const isValid = underAgeValidate(formattedDate);

    //scroll to top of page
    window.scrollTo(0, 0);

    if (isValid && year.length === 4) {
      setAge(true);
    } else if (year.length !== 4) {
      setDateMessage("Please enter your date of birth");
    } else {
      setDateMessage("You must be over 18 to enter");
    }
  };

  return (
    <AnimatePresence>
      <div className="background-blue text-white  my-10 max-w-[740px] max-h-[534px] m-auto self-center   relative rounded-[20px]">
        <div className="absolute top-0 left-0 px-11 hidden md:block">
          <img src={AgeTop} alt="" className="w-full object-contain" />
        </div>
        <div className="absolute top-0 left-0  md:hidden">
          <img src={AgeTopMobile} alt="" className="w-full object-contain" />
        </div>
        <div className="px-gutter md:px-40 py-28  w-[90vw] mx-auto md:w-auto text-center  ">
          <h2 className=" blockH1 pb-5">
            TAB is only for those over the age of 18
          </h2>
          <p className="blockB2">Please enter your date of birth.</p>
          <div className="mx-auto  w-full md:max-w-[342px] grid grid-cols-10 gap-x-2 md:gap-x-[22px] justify-center mt-7 ">
            <div className="col-span-3 ">
              <p className="blockH2 text-center mb-3 ">DAY</p>
              <input
                className="dateInput"
                ref={dayFocus}
                pattern="[0-9]*"
                type="number"
                maxlength="2"
                id="dateInput "
                autocomplete="off"
                placeholder="DD"
                required
                onChange={(e) => {
                  setDay(e.target.value);
                  if (e.target.value.length >= 2) monthFocus.current.focus();
                }}
              />
            </div>
            <div className="col-span-3">
              <p className="blockH2 text-center mb-3 ">MONTH</p>
              <input
                className="dateInput "
                ref={monthFocus}
                pattern="[0-9]*"
                type="number"
                maxlength="2"
                max="12"
                id="monthInput"
                autocomplete="off"
                placeholder="MM"
                required
                onChange={(e) => {
                  setMonth(e.target.value);
                  if (e.target.value.length >= 2) yearFocus.current.focus();
                }}
              />
            </div>
            <div className="col-span-4">
              <p className="blockH2 text-center mb-3 ">YEAR</p>
              <input
                className="dateInput "
                ref={yearFocus}
                pattern="[0-9]*"
                type="number"
                maxlength="4"
                id="yearInput"
                autocomplete="off"
                placeholder="YYYY"
                required
                onChange={(e) => {
                  setYear(e.target.value);
                  if (e.target.value.length >= 4) enterFocus.current.focus();
                }}
              ></input>
            </div>
          </div>
          <div className="w-fit flex mt-10 mx-auto  ">
            <button
              ref={enterFocus}
              className="button-two "
              onClick={handleEnter}
            >
              ENTER
            </button>
          </div>
          <p className="text-center text-orange-dark mt-2 text-[14px] md:text-[18px] ">
            {" "}
            {dateMessage}
          </p>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default AgeGate;
