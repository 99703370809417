import React from "react";

import TwoGroup from "@images/twoGroup.png";
import ThreeGroup from "@images/threeGroup.png";
import FourGroup from "@images/fourGroup.png";
import FiveGroup from "@images/fiveGroup.png";

const OtherSteps = () => {
  return (
    <div className="blockH2 md:grid grid-cols-2 gap-x-6 gap-y-20 space-y-20 md:space-y-0  max-w-[700px] mx-auto text-center pb-[100px]">
      <div className="grid grid-cols-1 grid-rows-[140px_90px]">
        <div className="">
          <img
            src={TwoGroup}
            alt=""
            className="w-[334px] object-contain mx-auto"
          />
        </div>
        <h3 className="mt-5">
          receive $15
          <br />
          voucher code
        </h3>
      </div>
      <div className="grid grid-cols-1 grid-rows-[140px_120px]">
        <div className="">
          <img
            src={ThreeGroup}
            alt=""
            className="w-[278px] object-contain mx-auto"
          />
        </div>
        <h3 className="mt-5">
          visit delivereasy and
          <br />
          select a chicken outlet
          <br />
          <span className="blockH3">[or other takeout]</span>
        </h3>
      </div>
      <div className="grid grid-cols-1 grid-rows-[200px_90px]">
        <div className="self-end pb-5">
          <img
            src={FourGroup}
            alt=""
            className="w-[334px] object-contain mx-auto"
          />
        </div>
        <h3 className="mt-5">
          choose
          <br />
          your feed
        </h3>
      </div>
      <div className="grid grid-cols-1 grid-rows-[200px_90px]">
        <div className=" ">
          <img
            src={FiveGroup}
            alt=""
            className="w-[262px] object-contain mx-auto"
          />
        </div>
        <h3 className="mt-5">
          receive it. eat it.
          <br />
          enjoy the game.
        </h3>
      </div>
    </div>
  );
};

export default OtherSteps;
